//LIGHT THEME

$primary: rgb(17 24 39);
$secondary: #272c36;
$error: #f44336;
$background: white;
$background-light: #F5F6FA;
$green: #7BBC71;

$primary-color: (
  50: $primary,
  100: $primary,
  200: $primary,
  300: $primary,
  400: $primary,
  500: $primary,
  600: $primary,
  700: $primary,
  800: $primary,
  900: $primary,
  A100: $primary,
  A200: $primary,
  A400: $primary,
  A700: $primary,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$secondary-color: (
    50: $secondary,
    100: $secondary,
    200: $secondary,
    300: $secondary,
    400: $secondary,
    500: $secondary,
    600: $secondary,
    700: $secondary,
    800: $secondary,
    900: $secondary,
    A100: $secondary,
    A200: $secondary,
    A400: $secondary,
    A700: $secondary,
    contrast: (
      50: white,
      100: white,
      200: white,
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: white,
      A200: white,
      A400: white,
      A700: white,
    )
  );

// DARK THEME
$primary-dark: #715AD5;
$secondary-dark: #c9d1d9;
$error-dark: #f44336;
$background-dark: #0d1117 ;
$background-light-dark: #161b22;
$title-dark: #c9d1d9;
$subtitle-dark: #8b949e;


$primary-color-dark: (
    50: $primary-dark,
    100: $primary-dark,
    200: $primary-dark,
    300: $primary-dark,
    400: $primary-dark,
    500: $primary-dark,
    600: $primary-dark,
    700: $primary-dark,
    800: $primary-dark,
    900: $primary-dark,
    A100: $primary-dark,
    A200: $primary-dark,
    A400: $primary-dark,
    A700: $primary-dark,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);

$secondary-color-dark: (
    50: $secondary-dark,
    100: $secondary-dark,
    200: $secondary-dark,
    300: $secondary-dark,
    400: $secondary-dark,
    500: $secondary-dark,
    600: $secondary-dark,
    700: $secondary-dark,
    800: $secondary-dark,
    900: $secondary-dark,
    A100: $secondary-dark,
    A200: $secondary-dark,
    A400: $secondary-dark,
    A700: $secondary-dark,
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);