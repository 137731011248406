/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.flex-center{
  display: flex;
  justify-content: center;
}

@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

@import "./variable.scss";


$candy-app-primary: mat-palette($primary-color);
$candy-app-accent:  mat-palette($secondary-color);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$candy-app-theme: mat-light-theme((
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warn: $candy-app-warn,  
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@media (prefers-color-scheme: light) {
    .app-label{
      color: rgba(0, 0, 0, 0.6);
    }

    @include angular-material-theme($candy-app-theme);
}


//DARK THEME
$dark-app-primary: mat-palette($primary-color-dark);
$dark-app-accent:  mat-palette($secondary-color-dark);

// The warn palette is optional (defaults to red).
$dark-app-warn:    mat-palette($mat-red);

$candy-dark-theme: mat-dark-theme(
  (
    color: (
      primary: $dark-app-primary,
      accent: $dark-app-accent,
      warn: $dark-app-warn
    ),
  )
);

.label-slide{
  margin-right: 20px;
  margin-bottom: 20px;
}

app-form-item{
  display: flex;
  flex-grow: 1;
  padding-right: 20px;
}

.full-width{
  width: 100%;
}

.flex-end{
  display: flex;
  justify-content: flex-end;
}

h1{
  font-size: 20px!important;
}

button{
    mat-icon{
       font-size: 18px!important;
    }
}

@media (prefers-color-scheme: dark) {
    @include angular-material-theme($candy-dark-theme);

    body{
        background-color: $background-light-dark;
    }

    h1{
      font-size: 20px!important;
      color: $title-dark;
    }

    th, td{
        background-color: $background-dark;
    }

    mat-card{
        background-color: $background-dark!important;
    }

    .mat-paginator-container{
      background-color: $background-light-dark!important;
    }

    .app-label{
      color: white;
    }
}
